<template>
  <b-container fluid :style="{marginTop:'60px'}">
    <div :style="{width:'100%'}">
      <v-card
        class="mx-auto my-12 pa-5"
        max-width="450">
            <b-row class="d-flex justify-content-center">
              <!-- <b-col class="d-none d-sm-block" md="12"> -->
              <b-col md="12">
                <img alt="Bufu logo" src="../assets/bufu-logo-load.png" width="150">
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center" v-if="!updated">
              <b-col md="12" >
                <b-form @submit="onSubmit">
                  <b-col md="12">
                    <h1 :class="`text-subtitle-1 font-weight-light mb-n1`" style="text-align:right" @click="showPass = !showPass">{{showPass ? 'sembunyikan':'tampilkan'}}</h1>
                    <b-input-group>
                      <b-form-input 
                        v-model="form.newPass"
                        :type="showPass ? 'text':'password'"
                        required
                        placeholder="Kata sandi baru"></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col md="12">
                    <b-input-group>
                      <b-form-input 
                        v-model="form.confirmNewPass"
                        :type="showPass ? 'text':'password'"
                        required
                        placeholder="Konfirmasi kata sandi"></b-form-input>
                    </b-input-group>
                  </b-col>
                <v-btn type="submit" color="primary" dark width="200" class="mt-5">
                    <span v-show="!isLoad">Reset kata sandi</span>
                    <b-spinner v-show="isLoad" small></b-spinner>
                </v-btn>
                <p v-if="message !== null" style="color:red; margin-top:20px">{{ message }}</p>
              </b-form>
              </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center" v-if="updated">
                <b-col md="12">
                    <h1 :class="`headline font-weight-light mb-4`">
                        Kata sandi berhasil diubah
                    </h1>
                </b-col>
                <b-col md="12">
                    <v-btn class="submit" rounded color="primary" dark to="/login" style="text-decoration:none">Kembali Login</v-btn>
                </b-col>
            </b-row>
      </v-card>
    </div>
  </b-container>
</template>
<script>

export default {
  name: 'ResetPassword',
  props: ['auth','target'],
  components: {

  },
  data() {
    return {
      form: {
          newPass: '',
          confirmNewPass: ''
      },
      email: '',
      updated: false,
      message: null,
      isLoad: false,
      showPass: false
    }
  },
  methods:{
      async onSubmit(evt) {
        this.isLoad = true
        evt.preventDefault()
        if(this.form.newPass !== this.form.confirmNewPass){
            this.isLoad = false
            this.message = 'Kata sandi tidak sama'
            return
        }

        await this.$root.api.sentAPI(
        {
          method: 'post',
          api: this.$root.api.post.changeForgotPassword,
          form: {
                email: this.email,
                newPassword: this.form.newPass,
                token: this.auth
            }
        })
        .then(res => {
          if(res.status.status === 'Success'){
              this.updated = true
          }else{
              this.message = res.status.message
          }
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })
        this.isLoad = false
      }
  },
  watch:{
      'form.newPass': function() {
          this.message = null
      },
      'form.confirmNewPass': function() {
          this.message = null
      }
  },
  created() {
    this.email = this.$aes.decrypt(this.target)
  }
}
</script>

<style scoped>
p{
  font-size: 12px
}

.v-application .text-subtitle-1{
  font-size: 12px !important;
  cursor: pointer;
}
</style>